import styles from '../../styles/components/Header.module.scss';
import Image from 'next/image';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { NavigationMenu } from '../../slices';
import { SliceZone } from '@prismicio/react';

export default function Header({ data }) {
  const [toggle, setToggle] = useState(false);
  const router = useRouter();

  const [headerData, setHeaderData] = useState(null);
  const [headerSubmenu, setHeaderSubmenu] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (data.headerData && data.headerSubmenu) {
      setHeaderData(data.headerData.data);
      setHeaderSubmenu(data.headerSubmenu);
    }
  }, [data]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      setToggle(false);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const search = (term) => {
    router.push({
      pathname: '/search-results',
      query: {
        term: term,
      },
    });
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSubmit = (e) => {
    search(searchValue);
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      handleSubmit();
    }
  };

  return (
    <div className={classnames(styles.header_container, styles.test)}>
      <div className={classnames(styles.header_inner)}>
        <div className={classnames(styles.logo_container)}>
          {headerData && (
            <Link href="/" passHref>
              <Image
                src={headerData.logo.url}
                alt={headerData.logo.alt || 'header logo'}
                width={headerData.logo.dimensions.width}
                height={headerData.logo.dimensions.height}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </Link>
          )}
        </div>
        <div className={classnames(styles.wrapper_mobile)}>
          <div
            className={classnames(
              styles.burger_menu,
              toggle && styles.burger_open
            )}
            onClick={() => setToggle(!toggle)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className={classnames(styles.menu_items, toggle && styles.open)}>
            {headerData && (
              <SliceZone
                slices={[
                  headerData.slices.find(
                    (element) => element.slice_type === 'navigation_menu'
                  ),
                ]}
                components={{
                  navigation_menu: ({ slice, ...props }) => (
                    <NavigationMenu
                      headerSubmenu={headerSubmenu}
                      slice={slice}
                      {...props}
                    />
                  ),
                }}
              />
            )}
            <div className={classnames(styles.header_search)}>
              <input
                type="search"
                placeholder="Search"
                value={searchValue}
                onChange={handleChange}
                onKeyPress={handleKeypress}
              />
            </div>
          </div>
          <div className={classnames(styles.menu_button)}>
            {headerData && headerData.contact && headerData.contact.url && (
              <Link
                href={headerData.contact.url}
                target={headerData.contact.target}
              >
                <button
                  name="contact page link"
                  aria-label="contact page link"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
