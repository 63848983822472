import Header from '../header/header';
import Footer from '../footer/footer';

export default function Layout({ headerData, footerData, children }) {
  return (
    <>
      <Header data={headerData} />
      <main>{children}</main>
      <Footer data={footerData.data} />
    </>
  );
}
