import Image from 'next/image';
import Layout from '../components/layout/layout';
import { createClient } from '../prismicio';
import parallaxImage from '../public/parallax.svg';
import styles from '../styles/Page.module.scss';

import * as prismic from '@prismicio/client';
import { SliceZone } from '@prismicio/react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { Parallax } from 'react-scroll-parallax';
import {
  Accordion,
  AnchorLinks,
  Awards,
  Benefits,
  Callout,
  CaseStudyListing,
  ComparisonTable,
  CustomerStories,
  ExpandableCards,
  ExpandableProfile,
  Form,
  FeaturesCarousel,
  FourColumnCardFeed,
  FourColumnSteps,
  HomeHero,
  InternalHero,
  MixedContent,
  NewsListing,
  PartnerCarousel,
  PartnersGrid,
  Quote,
  SearchResults,
  StaticTwoColumn,
  StatsCards,
  Testimonials,
  ThreeColumnCaseStudyFeed,
  ThreeColumnStatCards,
  TwoColumnText,
  V2GCalculator,
  VideoBlock,
  Wysiwyg,
} from '../slices';

export default function Page({
  header,
  footer,
  mixedContentCards,
  articleCards,
  articles,
  caseStudyCards,
  caseStudies,
  expandedCards,
  profileCategories,
  page,
}) {
  const router = useRouter();
  const componentRefs = useRef([]);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    componentRefs.current = componentRefs.current.slice(
      0,
      page.data.slices.length
    );
  }, [page.data.slices]);

  useEffect(() => {
    if (router.query && router.query.state === 'success') {
      setSuccess(true);
    } else {
      setSuccess(false);
    }
  }, [router.query]);

  return (
    <div className={styles.container}>
      <Head>
        <title>{page.data.title}</title>
        <meta name="title" content={page.data.metaTitle} />
        <meta name="description" content={page.data.metaDescription} />
        <meta property="og:title" content={page.data.metaTitle} />
        <meta property="og:description" content={page.data.metaDescription} />
        <meta
          property="og:image"
          content="https://images.prismic.io/fermata/1289debb-812e-4ef9-a383-75d02adf2a55_meta_image_fermataEnergy.jpg"
        />
        <meta name="twitter:title" content={page.data.metaTitle} />
        <meta name="twitter:description" content={page.data.metaDescription} />
        <meta
          name="twitter:image"
          content="https://images.prismic.io/fermata/1289debb-812e-4ef9-a383-75d02adf2a55_meta_image_fermataEnergy.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Layout headerData={header} footerData={footer}>
        <Parallax
          className={styles.parallaxWrapper}
          startScroll={0}
          endScroll={
            typeof document !== 'undefined'
              ? Math.max(
                  document.body.scrollHeight,
                  document.body.offsetHeight,
                  document.documentElement.clientHeight,
                  document.documentElement.scrollHeight,
                  document.documentElement.offsetHeight
                )
              : 3000
          }
          translateY={[10, -60]}
        >
          <Image
            className={styles.parallaxImg}
            src={parallaxImage}
            alt="parallax image"
            priority={true}
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </Parallax>
        <SliceZone
          slices={page.data.slices}
          components={{
            hero: (props) => {
              return (
                <HomeHero
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            awards: (props) => {
              return (
                <Awards
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            mixed_content: (props) => {
              return (
                <MixedContent
                  cards={mixedContentCards}
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            two_column_panel: (props) => {
              return (
                <StaticTwoColumn
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            partner_carousel: (props) => {
              return (
                <PartnerCarousel
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            partners_grid: (props) => {
              return (
                <PartnersGrid
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            callout: (props) => {
              return (
                <Callout
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            form: (props) => {
              return (
                <Form
                  slice={props.slice}
                  success={success}
                  slug={page.uid}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            four_column_card_feed: (props) => {
              return (
                <FourColumnCardFeed
                  cards={articleCards}
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            internal_hero: (props) => {
              return (
                <InternalHero
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            two_column_text: (props) => {
              return (
                <TwoColumnText
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            accordion: (props) => {
              return (
                <Accordion
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            quote: (props) => {
              return (
                <Quote
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            benefits: (props) => {
              return (
                <Benefits
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            wysiwyg: (props) => {
              return (
                <Wysiwyg
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            comparison_table: (props) => {
              return (
                <ComparisonTable
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            stats_cards: (props) => {
              return (
                <StatsCards
                  cards={mixedContentCards}
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            customer_stories: (props) => {
              return (
                <CustomerStories
                  cards={articleCards}
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            testimonials: (props) => {
              return (
                <Testimonials
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            features_carousel: (props) => {
              return (
                <FeaturesCarousel
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            news_listing: (props) => {
              return (
                <NewsListing
                  articles={articles}
                  cards={articleCards}
                  slice={props.slice}
                  query={router.query}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            anchor_links: (props) => {
              return (
                <AnchorLinks
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  componentRefs={componentRefs}
                  {...props}
                />
              );
            },
            search_results: (props) => {
              return (
                <SearchResults
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            expandable_profile: (props) => {
              return (
                <ExpandableProfile
                  slice={props.slice}
                  categories={profileCategories}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            three_column_stat_cards: (props) => {
              return (
                <ThreeColumnStatCards
                  slice={props.slice}
                  cards={mixedContentCards}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            three_column_case_study_feed: (props) => {
              return (
                <ThreeColumnCaseStudyFeed
                  slice={props.slice}
                  cards={caseStudyCards}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            four_column_steps: (props) => {
              return (
                <FourColumnSteps
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            case_study_listing: (props) => {
              return (
                <CaseStudyListing
                  caseStudies={caseStudies}
                  cards={caseStudyCards}
                  slice={props.slice}
                  query={router.query}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            expandable_cards: (props) => {
              return (
                <ExpandableCards
                  cards={expandedCards}
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            v2_g_calculator: (props) => {
              return (
                <V2GCalculator
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
            video_block: (props) => {
              return (
                <VideoBlock
                  slice={props.slice}
                  ref={(el) => (componentRefs.current[props.index] = el)}
                  {...props}
                />
              );
            },
          }}
        />
      </Layout>
    </div>
  );
}

export async function getStaticProps({ params, previewData }) {
  const client = createClient({ previewData });

  const [
    headerData,
    footer,
    headerSubmenu,
    mixedContentCards,
    articleCards,
    caseStudyCards,
    expandedCards,
    profileCategories,
    articles,
    caseStudies,
  ] = await Promise.all([
    client.getSingle('header'),
    client.getSingle('footer'),
    client.getAllByType('navigationsubmenu'),
    client.getAllByType('mixedcontentcard'),
    client.getAllByType('articlecard'),
    client.getAllByType('casestudycard'),
    client.getAllByType('expandedcard'),
    client.getAllByType('profile_category'),
    client.get({
      filters: [prismic.filter.at('document.type', 'article')],
      fetch: ['article.cardlink'],
    }),
    client.get({
      filters: [prismic.filter.at('document.type', 'casestudy')],
      fetch: ['casestudy.cardlink'],
    }),
  ]);

  const header = { headerData, headerSubmenu };

  const page = await client.getByUID('page', params.slug);

  return {
    props: {
      header,
      footer,
      mixedContentCards,
      articleCards,
      articles,
      caseStudyCards,
      caseStudies,
      expandedCards,
      profileCategories,
      page,
    },
  };
}

export async function getStaticPaths() {
  const client = createClient();

  const pages = await client.getAllByType('page');

  return {
    paths: pages.map((page) => prismic.asLink(page)),
    fallback: false,
  };
}
