import styles from '../../styles/components/Footer.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import classnames from 'classnames';
import { components } from '../../slices';
import { SliceZone } from '@prismicio/react';
import mixedArrow from '../../public/mixed-arrow.svg';
import upArrow from '../../public/icon_up.svg';
import { PrismicRichText } from '@prismicio/react';

export default function Footer({ data }) {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <footer className={styles.footer}>
      <div className={styles.upper_footer}>
        <div className={styles.scrollTop} onClick={handleClick}>
          <Image
            src={upArrow}
            alt="Top Arrow"
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </div>
        <div className={styles.upper_logo_links}>
          <Link href="/">
            <span className={styles.logo}>
              <Image
                src={data.logo.url}
                alt={data.logo.alt || 'logo'}
                width={data.logo.dimensions.width}
                height={data.logo.dimensions.height}
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </span>
          </Link>
          <div className={styles.upper_links}>
            <Link href={`mailto:${data.mailtext}`}>{data.mailtext}</Link>
            <Link href={`tel:${data.phonetext}`}>{data.phonetext}</Link>
            <span>{data.geotext}</span>
          </div>
        </div>
        <div className={styles.upper_cta}>
          <div className={styles.bottom}>
            <div className={styles.strapline}>
              <PrismicRichText field={data.strapline} />
            </div>
            <div className={styles.uppercta}>
              {data.upperctalink.url && (
                <Link
                  href={data.upperctalink.url}
                  target={data.upperctalink.target}
                >
                  <button
                    className={classnames(styles.btn, styles.tertiary_white)}
                    name="upper cta link"
                  >
                    <span className={styles.btnCopy}>{data.upperctatext}</span>
                    <Image
                      src={mixedArrow}
                      alt="right_arrow"
                      width={data.middlectalogo.dimensions.height}
                      height={data.middlectalogo.dimensions.height}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                      }}
                    />
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.middle_footer}>
        <div>
          <div className={styles.footerMenuWrapper}>
            <SliceZone
              slices={[
                data.slices.find(
                  (element) => element.slice_type === 'footer_menu'
                ),
              ]}
              components={{
                footer_menu: components.footer_menu,
              }}
            />
          </div>
          <div className={styles.footerSocialMenuWrapper}>
            <SliceZone
              slices={[
                data.slices.find(
                  (element) => element.slice_type === 'footer_social'
                ),
              ]}
              components={{
                footer_social: components.footer_social,
              }}
            />
          </div>
        </div>
        <div className={styles.btnWrapper}>
          {data.middlectalink.url && (
            <Link
              href={data.middlectalink.url}
              target={data.middlectalink.target}
            >
              <button
                className={classnames(styles.btn, styles.secondary_white)}
                name="middle cta link"
              >
                <span className={styles.btnCopy}>{data.middlectatext}</span>
              </button>
            </Link>
          )}
        </div>
      </div>
      <div className={styles.lower_footer}>
        <div className={styles.footer_copyright}>
          <p>{data.copyright[0].text}</p>
        </div>
        <div className={styles.footerLegalMenuWrapper}>
          <SliceZone
            slices={[
              data.slices.find(
                (element) => element.slice_type === 'footer_legal_menu'
              ),
            ]}
            components={{
              footer_legal_menu: components.footer_legal_menu,
            }}
          />
        </div>
      </div>
    </footer>
  );
}
